<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="PO - Purchase Order"
        :hasDropdown="false"
        :filter="filter"
        placeholder="Search PO. Number"
        @search="getList"
        :hasFilter="false"
        routerPath="/setting/inventory/purchase-order/detail/0"
      >
      </HeaderPanel>

      <!-- </ul> -->

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-tabs content-class="" v-model="tabIndex">
          <b-tab
            v-for="status of statusList"
            :key="status.id"
            :value="status.id"
            @click="onTabChange(status.id, $event)"
          >
            <template #title>
              {{ status.name }}

              ({{ status.count }})
            </template>
            <b-row>
              <b-col>
                <b-table
                  responsive
                  striped
                  hover
                  :fields="fields"
                  :items="items"
                  :busy="isBusy"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template v-slot:cell(po_no)="{ item }">
                    <router-link
                      :to="
                        '/setting/inventory/purchase-order/detail/' + item.po_id
                      "
                      ><span class="underline">{{
                        item.po_no
                      }}</span></router-link
                    >
                  </template>
                  <template v-slot:cell(order_date)="{ item }">
                    <div>{{ item.order_date | moment($formatDateNew) }}</div>
                  </template>
                  <template v-slot:cell(due_date)="{ item }">
                    <div>{{ item.due_date | moment($formatDateNew) }}</div>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <Pagination
              @handleChangeTake="handleChangeTake"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        search: "",
        po_status: [0], 
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      tabIndex: 0,
      statusList: [],
      fields: [
        {
          label: "หมายเลข PO",
          key: "po_no",
        },
        {
          label: "วันที่ออก",
          key: "order_date",
        },
        {
          label: "กำหนดส่งมอบ",
          key: "due_date",
        },

        {
          label: "จำนวนรายการ",
          key: "total_product",
        },

        {
          label: "รับแล้ว/ชิ้น",
          key: "total_received_product",
        },
        {
          label: "รับโดย",
          key: "created_by",
        },
        {
          label: "ผู้ออก",
          key: "updated_by",
        },
        {
          label: "สถานะ",
          key: "status",
        },
      ],
      items: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
    };
  },
  async created() {
    await this.getStatus();
    await this.getList();
  },

  methods: {
    async getList() {
    this.filter.page = 1
      let payload = { ...this.filter };
      if (typeof payload.status == "object" && payload.status) {
        if (payload.status.length > 1) payload.status = null;
        else if (payload.status.length == 1) payload.status = payload.status[0];
        else payload.status = null;
      }
      this.isBusy = true;
      const response = await this.axios.post(`/po/list`, payload);

      this.rows = response.data.detail.total_count;
      this.items = response.data.detail.po_list_response;
      this.isBusy = false;
      this.isLoading = false;
    },
    async getStatus() {
      this.isBusy = true;
      let payload = { ...this.filter };

      const res = await this.axios.post(`/po/status`, payload);
      if (res.data.result == 1) {
        this.statusList = res.data.detail;
      }

      this.isBusy = false;
    },
    onTabChange(val, e) {
      this.filter.po_status = [val];
      this.filter.page = 1;
      this.showing = 1;
      this.showingTo = this.filter.take;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    openFilter() {
      this.$refs.filter.show();
    },

    clearFilter() {
      this.filter = {
        search: "",
        status: 1, // null = all,1 = active,0=inactive
        page: 1,
        take: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="scss">
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
